@use "sass:map";
@import "variables";
// Buttons

$primary-btn: (
  "text-color": $white,
  "bg-color": $primary-500,
  "border-color": 1px solid $primary-500,
  "hover-text-color": $white,
  "hover-bg-color": $primary-500,
  "hover-border-color": 1px solid $primary-500,
  "disable-text-color": $black-300,
  "disable-bg-color": $black-200,
  "disable-border-color": none,
);
$secondary-btn: (
  "bg-color": $white,
  "text-color": $primary-500,
  "border-color": 1px solid $primary-500,
  "hover-bg-color": $primary-500,
  "hover-text-color": $white,
  "hover-border-color": 1px solid $primary-500,
  "disable-text-color": $black-300,
  "disable-bg-color": $white,
  "disable-border-color": 1px solid $black-300
);
$outline-btn: (
  "bg-color": $white,
  "text-color": $black-900,
  "border-color": 1px solid $black-900,
  "hover-bg-color": $white,
  "hover-text-color": $primary-500,
  "hover-border-color": 1px solid $primary-500,
  "disable-text-color": $black-300,
  "disable-bg-color": $white,
  "disable-border-color": 1px solid $black-300,
);
$danger-btn: (
  "bg-color": $danger-500,
  "text-color": $white,
  "border-color": 1px solid $danger-500,
  "hover-bg-color": $danger-500,
  "hover-text-color": $white,
  "hover-border-color": 1px solid $danger-500,
  "disable-text-color": $black-300,
  "disable-bg-color": $black-200,
  "disable-border-color": none,
);

$buttons: (
  "primary": $primary-btn,
  "secondary": $secondary-btn,
  "outline": $outline-btn,
  "danger": $danger-btn
);

@mixin button($bg-color, $text-color, $border, $hover-bg-color, $hover-text-color, $hover-border-color, $disable-text-color, $disable-bg-color, $disable-border-color) {
  background-color: $bg-color;
  color: $text-color;
  font-weight: 700;
  border: $border;

  &:disabled,
  &.disabled {
    @include button-disable($disable-text-color, $disable-bg-color, $disable-border-color);
    pointer-events: none;
  }
  &:hover {
    @include button-hover($hover-text-color, $hover-bg-color, $hover-border-color);
  }
}

@mixin button-disable($disable-text-color, $disable-bg-color, $disable-border-color) {
  color: $disable-text-color;
  background-color: $disable-bg-color;
  border: $disable-border-color;
}

@mixin button-hover($hover-text-color, $hover-bg-color, $hover-border-color) {
  color: $hover-text-color;
  background-color: $hover-bg-color;
  border: $hover-border-color;
}

@each $name, $btn in $buttons {
  .btn-#{$name} {
    @include button(
        map_get($btn, "bg-color"),
        map_get($btn, "text-color"),
        map_get($btn, "border-color"),
        map_get($btn, "hover-bg-color"),
        map_get($btn, "hover-text-color"),
        map_get($btn, "hover-border-color"),
        map_get($btn, "disable-text-color"),
        map_get($btn, "disable-bg-color"),
        map_get($btn, "disable-border-color"),
    )
  }
}


.btn {
	border-radius: 5px;
}
.btn.disabled, .btn:disabled {
    cursor: not-allowed;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}
.btn-white:focus, .btn-white:hover {
    background-color: #f7f7f7;
}
.rounded,
.circle {
	border-radius: 500px;
}


.bg-white { background-color: #fff; }
.text-muted { color: #8e8e8e !important; }

$btn_type: ("primary": $color-primary, "success": $color-success, "info": $color-info, "warning": $color-warning,"danger": $color-danger, "white": $color-white);
@each $name, $option in $btn_type {
  .btn-#{$name} {
    background-color: $option;
    border: 1px solid $option;
    color: #fff;
  }

  .bg-#{$name},
  .badge-#{$name} {
    background-color: $option !important;
    color: #fff;
  }

  .text-#{$name},
  .dropdown-menu > li > a.text-#{$name} {
    color: $option !important;
  }
}
.btn-white {
  color: #000000;
  border: 1px solid #aaa;
}

$btn_type_hover: ("primary": $color-primary-hover, "success": $color-success-hover, "info": $color-info-hover,
                  "warning": $color-warning-hover,"danger": $color-danger-hover, "white": $color-white-hover);
@each $name, $option in $btn_type_hover {
  .btn-#{$name}:hover,
  .btn-#{$name}:focus,
  .btn-#{$name}.active,
  .btn-#{$name}:active,
  .open > .dropdown-toggle.btn-#{$name} {
    background-color: $option;
    border: 1px solid $option;
  }
  .btn-#{$name}.active.focus,
  .btn-#{$name}.active:focus,
  .btn-#{$name}.active:hover,
  .btn-#{$name}.focus:active,
  .btn-#{$name}:active:focus,
  .btn-#{$name}:active:hover,
  .open > .dropdown-toggle.btn-#{$name}.focus,
  .open > .dropdown-toggle.btn-#{$name}:focus,
  .open > .dropdown-toggle.btn-#{$name}:hover {
    background-color: $option;
    border: 1px solid $option;
  }
  .btn-#{$name}.active:not(:disabled):not(.disabled),
  .btn-#{$name}:active:not(:disabled):not(.disabled),
  .show > .btn-#{$name}.dropdown-toggle {
    background-color: $option;
    border-color: $option;
    color: #fff;
  }
  .btn-#{$name}.active:focus:not(:disabled):not(.disabled),
  .btn-#{$name}:active:focus:not(:disabled):not(.disabled),
  .show > .btn-#{$name}.dropdown-toggle:focus {
    box-shadow: unset;
  }
  .btn-#{$name}.disabled, .btn-#{$name}:disabled {
      background-color: $option;
      border-color: $option;
      color: #fff;
  }
}

.btn-white.disabled,
.btn-white:disabled {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
  color: #757575;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f56954;
  border-color: #f56954;
  color: #757575;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover ,
.btn-dark:focus ,
.btn-dark.active ,
.btn-dark:active
{
  color: #fff;
  background-color: #4a4d50;
  border-color: #4a4d50;
}

.btn.circle{
  border-radius: 50%;
  padding: 0;
  text-align: center;
}

.btn-md.circle{
  width: 40px;
  height: 40px;
  line-height: 100%;
  font-size: 25px;
}
.btn-lg.circle{
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 30px;
}
