/*
just declare style for all
ex:
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

@import "variables";
@import "functions";
@import "uniti";
@import "~bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "cdk/cdk";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Style Additional
@import "type";
@import "buttons";
@import "tooltip";
@import "checkbox";
@import "radio";
@import "table";
body{
  //@media screen  and (max-width :990px) {
    overflow: hidden;
  //}
}
////*restyle nav-tabs*/
.nav-tabs{
  border-bottom: 1px solid #dee2e6;
  .nav-item {
    margin-bottom: -1px;
    .nav-link{
      border: 1px solid transparent;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      &.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
      }
    }
    &.show .nav-link,
    .nav-link.active {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;
    }
  }
  .tab-content{
    // border-left: 1px solid #dee2e6;
    // border-right: 1px solid #dee2e6;
    // border-bottom: 1px solid #dee2e6;
    // padding: 10px;
    // border-radius: 0 0 3px 3px;
    // margin-top: 0 !important;
  }
}
//Inputs
.form-control {
  line-height: 21px;
}

////* Restyle ng-select */
.ng-select {
  font-family: $font-family-base;
  border: none;
  padding: 0;

  &.ng-select-single, &.ng-select-multiple {
    .ng-select-container {
      max-height: 39;
      border-radius: $border-radius;

      .ng-input {
        top: auto !important;
      }
    }
  }
}

////* Style input having addon */
.inner-addon {
  position: relative;

  .inner-icon-left, .inner-icon-right {
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 3em;
    height: 100%;
    z-index: 4;
  }

  &.left-addon {
    input {
      padding-left: 3em;
    }

    .inner-icon-left {
      left: 0;
    }
  }

  &.right-addon {
    input {
      padding-right: 3em;
    }

    .inner-icon-right {
      right: 0;
    }
  }
}

//* Required */
.required-label:after {
  font-family: 'Font Awesome 5 Free', serif;
  content: fa-content($fa-var-asterisk);
  font-size: 0.5rem;
  font-weight: 900;
  color: $danger-500;
  margin-left: 0.25rem;
  vertical-align: top;
}

//* Popup size*/
@for $i from 1 through 20 {
  .modal-dialog.modal-#{$i * 5} {
    width: $i * 5%;
    max-width: unset;
  }
}

@for $i from 1 through 100 {
  .column-w-#{$i} {
    width: $i * 1%;
  }
  .column-left-#{$i} {
    width: $i * 1%;
    float: left;
  }
  .column-right-#{$i} {
    width: $i * 1%;
    float: right;
  }
  .row{
    .column-w-#{$i} {
      flex: 0 0 $i * 1%;
      max-width: $i * 1%;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

//* Others */
.monospace-font {
  font-family: $font-family-monospace;
}

.display-content {
  display: contents !important;
}

.input-group {
  .inner-addon.right-addon input {
    //border-right: none !important;
  }

  .input-group-append {
    margin-left: 0 !important;
  }
}

app-select-search-with-popup, app-number-input {
  display: block !important;
}

.component-select {
  .modal-body {
    min-height: 820px;

    .table-resizable {
      overflow: auto;
    }
  }
}

.input-group {
  input[disabled] {
    background-color: #e9ecef !important;
    border-top: 1px $black-300 solid !important;
    border-left: 1px $black-300 solid !important;
    border-bottom: 1px $black-300 solid !important;
  }
}

.selected-row {
  .input-group {
    input[disabled] {
      background-color: #9FD4FF !important;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

//* restyle fieldset-legend */
fieldset.filter-collapse {
  border-top: 1px solid lighten($black-500, 40);
  padding: 0.25rem 1rem;

  legend {
    font-size: 1rem;
    padding: 0 0.5rem;
    margin: 0;
    width: auto;
    cursor: pointer;

    i.filter-collapse-icon {
      font-size: 1rem;
      width: 0.6rem;

      &:before {
        content: "\f0da";
      }
    }
  }

  &.expand {
    background: white;
    border: 1px solid lighten($black-500, 40);
    box-shadow: 1px 1px 2px rgba(85, 97, 135, 0.1);
    padding: 0.5rem 1rem 1rem;

    i.filter-collapse-icon {
      &:before {
        content: "\f0d7";
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}

.btn-custom{
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  padding: 0.45rem 1.25rem;
  &.btn-sm{
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    border-radius: 15px;
  }
}

td .btn-custom{
  padding: 0.2rem 0.75rem;
  font-size: 12px;
  &.btn-sm{
    padding: 0.15rem 0.5rem;
    border-radius: 20px;
  }
}

/* width */
.menu-left::-webkit-scrollbar {
  width: 0;
}

.btn-cicrle{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  font-size: 12px;
}

.tree-view{
  .items{
    border-left: 1px dotted #555;
    &.items-0{
      border-left: none;
      .item-0{
        padding-top: 0;
      }
    }
    .item{
      list-style: none;
      padding-top: 15px;
      .icon{ font-size: 20px; margin:0 5px 0 0;  }
      .icon-folder{cursor: pointer; }
      position: relative;
      @media screen and (min-width:858px) {
        width: 30%;
        justify-content: space-between;
        align-items: center;
      }
      @media screen and(max-width:858px) {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &::before{
        width: 36px;
        display: block;
        position: absolute;
        left: -38px;
        content: " ";
        border-top: 1px dotted #555;
        top: 23px;
      }
      &.item-0::before{
        display: none;
      }
      &.item-open{
        position: relative;
        &::after{
          width: 1px;
          display: block;
          position: absolute;
          left: 0;
          content: " ";
          bottom: 0;
          height: 10px;
          background: #fff;
          z-index: 9;
        }

      }
    }
  }
}


.ng-select.ng-select-single .ng-select-container {
  height: 35px !important;
  min-height: unset !important;
}

.time-picker {
  border: 1px solid #cccccc;
  input {
    border: none;
    height: 35px;
  }

  &.border-danger {
    .ngb-tp-spacer {
      color: #dc3545;
      font-weight: bold;
    }
  }
}

.col-form-label{
  font-weight: bold;
}


$table_border: 1px solid #dee2e6;
$table_cell_padding: 0.3rem;
$table_bg_color: #eee;
$table_bg_color_hover: rgb(250, 255, 204);

.table-hover tbody tr{
  &:nth-child(2n){
    background: $table_bg_color;
   }
  &:hover {
    color: #10182B;
    background-color: $table_bg_color_hover !important;
  }
}

.table-edit{
   border: $table_border;
   .table-head{
       background: $table_bg_color;
       border-bottom: $table_border;
        padding-left: 50px;
       position: relative;
       .table-head-row{
           .table-head-col{
               border-right: $table_border;
               .table-head-cell{
                   padding:  3px;
                   text-align: center;
               }

               &:last-child{
                   border-right: none;
               }
           }
       }

   }
   .table-body{
       .table-body-row{
           border-bottom: $table_border;
           position: relative;
           padding-left: 50px;
           .table-body-col{
               border-right: $table_border;
               .table-body-cell{
                   padding:  $table_cell_padding 0;
               }
               &:first-child{
                   .table-body-cell{
                       padding-left: $table_cell_padding;
                   }
               }
               &:last-child{
                   border-right: none;
                   .table-body-cell{
                       padding-right: $table_cell_padding;
                   }
               }
           }
           &:last-child{
               border-bottom: none;
           }

           &:nth-child(2n){
            background: $table_bg_color;
           }
           &:hover{
            background: $table_bg_color_hover;
           }
       }
       .btn-sm{
         padding: 0.2rem 0.3rem;
       }
   }
   .action{
       position: absolute;
       height: 40px;
       line-height: 40px;
       left: 0;
       width: 50px;
       text-align: center;
       border-right:  $table_border;
   }
   .row{
    margin-right: -$table_cell_padding;
    margin-left: -$table_cell_padding;
    .table-body-col{
      padding-right: $table_cell_padding;
      padding-left: $table_cell_padding;
    }
   }
   .ng-select.ng-select-single .ng-select-container {
    height: 30px !important;
    min-height: unset !important;
  }
}
.list-posistions{
   position: relative;
   .btn-add{
       position: absolute;
       right: 4px;
       top: 4px;
       z-index: 10;
   }
}

.disabled-content {
  pointer-events: none;
  opacity: 0.65;
}

a {
  cursor: pointer;
}

.ngb-tp-input-container {
  width: 3.5em !important;
}

.box-info{
  position: relative;
  .sub-title{ height: 20px; }
  .item{
    .name{ font-weight: bold; }
    &.active{
      z-index: unset;
      color: #495057;
      text-decoration: none;
      background: #c4e2ff;
      border: 1px solid rgba(0, 0, 0, 0.13);
    }
  }
  .box-body{
    height: calc(50vh - 140px);
    height: calc(-webkit-fill-available * 0.5 - 140px);
    overflow: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #ccc;
  }
  .sub-content {
    border-bottom: 1px solid;
  }


  &.box-info-pc{
    .card {
      border: none;
      .card-body {
        padding: 0;
      }
      //max-height: calc(100vh - 120px);
      .card-header {
        color: white;
        min-height: 65px;
      }
    }

    .accordion .card .show {
      max-height: calc(100vh - 120px);
      overflow: auto;
      overflow-x: hidden;
    }
  }

  .btn-plus {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
  }


  &.box-site{
    .card {
      ::after {
        position: absolute;
      }
    }
  }

  &.box-subject{
    .card-body {
      .row {
        padding: 0;
		    border-top: none;
        margin: 0 -1px;
      }
    }
  }
}

.ng-star-inserted {
  // overflow: auto;
  // overflow-x: hidden;
}

.hover-line:hover {
  background-color: #BFE5FF;
}

.page-sync-color{
  .col-list{
    padding-right: 0;
    .card{ border-right: none; }
    .card-body{
      padding: 0;
    }
  }
  .col-detail{
    border: 1px solid #b5b5b5;
    padding: 15px;
    margin-left: -1px;
  }
  .tab-content{
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 3px;
    margin: 15px !important;
  }
}

.scroll-mini{
  &::-webkit-scrollbar
    {
      width: 8px;
      background-color: #eff1f4;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 3px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0,0,0,.3);
    }
}
.label-required {
  font-size: 12px;
  color: red;
}
.optional {
  font-size: 12px;
}

.list-scroll{
  .content {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
    &.content-list{
      direction: rtl;
    }
    &::-webkit-scrollbar
    {
      width: 3px;
      background-color: #eff1f4;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 3px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0,0,0,.3);
    }
  }

  .content-detail {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
    &.content-list{
      direction: rtl;
    }
    &::-webkit-scrollbar
    {
      width: 12px;
      background-color: #eff1f4;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 3px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0,0,0,.3);
    }
  }

  .items {
    direction: ltr;
    .item{


      .item-inner{
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        .text {
          margin-top: auto;
          margin-bottom: auto;
        }
        .btn-delete{
          cursor: pointer;
          font-size: 14px;
          display: none;
          &.show{ display: block; }
          &:hover{
            color: red;
          }
        }
      }
    }

    &.items-list{
        .item{
          cursor: pointer;
          background: rgba(0, 0, 0, 0.03);
          border-bottom: 1px solid rgb(0 0 0 / 13%);
          border-left: 5px solid transparent;
          border-top: 1px solid transparent;

          &:hover,
          &.active{
            color: #000 !important;
            background-color: #fff !important;
            border-left: 5px solid $color-info   !important;
            border-top: 1px solid #b5b5b5 !important;
            border-bottom: 1px solid #b5b5b5 !important;
            .btn-delete{
              display: block;
            }
          }
        }
    }
  }
}

.text-small{
  font-size: xx-small;
}
.text-yellow{ color: red; }

.box {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.flex{
  display: flex;
}
.modal-xlg{
  width: 1200px;
  max-width: 90%;
}
.input-line{
  border: none;
  border-bottom: 1px solid #cecece;
  padding: 5px 10px;
  outline: none;
}

.hover:hover{
  cursor: pointer;
}

.preview-image{
  width: 120px;
  height: 120px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  .btn-clear-image {
    position: absolute;
    bottom: 25px;
    right: 7px;
  }

  .btn-md.circle {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 25px;
  }

  .avatar{
    font-size: 80px;
    color: #8e8e8e;
    line-height: 120px;
  }
  .change-avatar{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    text-align: center;
    width: 100%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    cursor: pointer;
    .icon{

    }
  }
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.list-group-item.active
{
  background-color: $color-info;
  border-color: $color-info;
}

.left{ float: left; }
.text-disable{ color: #b7b7b7; }
.use-menu-list{
  margin: 0;
  padding: 0;
  .item{
    display: inline;
    .sub-menu{

    }
  }
}
.list-lang{
  background: #3c8dbc;
    color: #fff;
}

.fixed-bottom {
  overflow: hidden;
  background: #f3f3f3;
}
.table-list{
  tr{
    &:nth-child(2n + 1){
      td{
        background: #f9f9f9;
      }
    }
  }
}

.nav-item .dropdown-menu {
  margin-top: 10px;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
}
.pr-sort{
  margin-right: 300px;
}

.toast-top-right{
  top: 72px !important;
}

.form-check-input {
  margin-top: 0.2rem;
}
.progress{
    background-color: #fff !important;
    .progress-bar{
      background-color: #e9ecef !important;
    }
}
.text-break{
  word-break: break-all;
}
.badge-status{ padding: 7px; }

.status-new {
  background-color: #fff;
  border: 1px solid #3a4d55;
}

.status-consent {
  background-color: #e2f5fc;
  border: 1px solid #0079ad;
}

.status-cancel {
  background-color: #f9f9f9;
  border: 1px solid #6c757d;
}
.status-consented {
  background-color: #dfefd0;
  border: 1px solid #59b40e;
}
.status-reconsent {
  background-color: #fff3c6;
  border: 1px solid #ffb92e;
}
.status-decline {
  background-color: #ffded7;
  border: 1px solid #ed6b50;
}
.text-longest {
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  text-decoration: underline;
}

.tooltip-inner__text-break-line {
  white-space: pre-line;
}

@media screen and (min-width:768px) {
  .sign-control-xs{
    display: none;
  }
  .sign-control-md{
    display: block;
    &.sign-control{
      margin-right: 160px;
      position: relative;
    }
    .signature-pad-canvas{
      // width: 100% !important;
      // height: 150px;
    }
    .sign-date-element{
      position: absolute;
      right: -160px;
      top: 50%;
      margin-top: -16px;
    }
    .mat-datepicker-input{ width: 140px; }
  }

  .signature-control{ 
    .signature-pad-canvas{
    }
  }
  .signature-final{ 
    .signature-pad-canvas{
    }
  }
}

@media screen and (min-width:1024px) { 
  .signature-control{ 
    .signature-pad-canvas{
    }
  }
  .signature-final{ 
    .signature-pad-canvas{
    }
  }
}

@media screen and (min-width: 1366px) {
  .signature-control{ 
    .signature-pad-canvas{
    }
  }
  .signature-final{ 
    .signature-pad-canvas{
    }
  }
}


@media screen and (max-width:767px) {
  .sign-control-md{
    display: none;
  }
}