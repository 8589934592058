// color
$white: #fff;
$black: #000;

$color-primary: #357ca5;
$color-primary-hover: #3c8dbc;

$color-success: #008d4c;
$color-success-hover: #00a65a;

$color-info: #00a7d0;
$color-info-hover: #00c0ef;

$color-warning: #db8b0b;
$color-warning-hover: #f39c12;

$color-danger: #d33724;
$color-danger-hover: #f56954;

$color-white: #ffffff;
$color-white-hover: #eeeeee;

$primary-100: #D2DCFE;
$primary-200: #A5B7FE;
$primary-300: #7891FC;
$primary-400: #5672FA;
$primary-500: #1F41F7;
$primary-600: #1631D4;
$primary-700: #0F23B1;
$primary-800: #09188F;
$primary-900: #051076;

$success-100: #EDFDD4;
$success-200: #D6FCAA;
$success-300: #B7F67E;
$success-400: #98EE5D;
$success-500: #6BE42B;
$success-600: #4DC41F;
$success-700: #34A415;
$success-800: #20840D;
$success-900: #116D08;

$info-100: #DFF3FF;
$info-200: #BFE5FF;
$info-300: #9FD4FF;
$info-400: #87C4FF;
$info-500: #5FA9FF;
$info-600: #4583DB;
$info-700: #2F62B7;
$info-800: #1E4493;
$info-900: #122F7A;

$warning-100: #FFF5D6;
$warning-200: #FFE7AE;
$warning-300: #FFD686;
$warning-400: #FFC668;
$warning-500: #FFAB36;
$warning-600: #DB8827;
$warning-700: #B7681B;
$warning-800: #934C11;
$warning-900: #7A380A;

$danger-100: #FEE3D5;
$danger-200: #FEC1AC;
$danger-300: #FD9782;
$danger-400: #FC7063;
$danger-500: #FB3030;
$danger-600: #D72332;
$danger-700: #B41833;
$danger-800: #910F31;
$danger-900: #78092F;

$black-100: #F4F6F6;
$black-200: #EAEDEE;
$black-300: #C8CBCD;
$black-400: #96999C;
$black-500: #56585A;
$black-600: #3E454D;
$black-700: #2B3340;
$black-800: #1B2434;
$black-900: #10182B;

$light-100: #FCFCFC;
$light-200: #FCFCFC;
$light-300: #FBFBFB;
$light-400: #F9F9F9;
$light-500: #F8F8F8;
$light-600: #F6F6F6;
$light-700: #F5F5F5;
$light-800: #F3F3F3;
$light-900: #F2F2F2;

$custom-colors: (
  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,
  "primary-600": $primary-600,
  "primary-700": $primary-700,
  "primary-800": $primary-800,
  "primary-900": $primary-900,
  "success-100": $success-100,
  "success-200": $success-200,
  "success-300": $success-300,
  "success-400": $success-400,
  "success-500": $success-500,
  "success-600": $success-600,
  "success-700": $success-700,
  "success-800": $success-800,
  "success-900": $success-900,
  "info-100": $info-100,
  "info-200": $info-200,
  "info-300": $info-300,
  "info-400": $info-400,
  "info-500": $info-500,
  "info-600": $info-600,
  "info-700": $info-700,
  "info-800": $info-800,
  "info-900": $info-900,
  "warning-100": $warning-100,
  "warning-200": $warning-200,
  "warning-300": $warning-300,
  "warning-400": $warning-400,
  "warning-500": $warning-500,
  "warning-600": $warning-600,
  "warning-700": $warning-700,
  "warning-800": $warning-800,
  "warning-900": $warning-900,
  "danger-100": $danger-100,
  "danger-200": $danger-200,
  "danger-300": $danger-300,
  "danger-400": $danger-400,
  "danger-500": $danger-500,
  "danger-600": $danger-600,
  "danger-700": $danger-700,
  "danger-800": $danger-800,
  "danger-900": $danger-900,
  "black-100": $black-100,
  "black-200": $black-200,
  "black-300": $black-300,
  "black-400": $black-400,
  "black-500": $black-500,
  "black-600": $black-600,
  "black-700": $black-700,
  "black-800": $black-800,
  "black-900": $black-900,
  "light-100": $light-100,
  "light-200": $light-200,
  "light-300": $light-300,
  "light-400": $light-400,
  "light-500": $light-500,
  "light-600": $light-600,
  "light-700": $light-700,
  "light-800": $light-800,
  "light-900": $light-900,
);

