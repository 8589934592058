@use "sass:map";
// Buttons

$tooltip-info: (
  "border-color": $black-500,
  "text-color": $black-900,
  "bg-color": $white,
);
$tooltip-success: (
  "border-color": $success-500,
  "text-color": $success-700,
  "bg-color": $success-100,
);
$tooltip-warning: (
  "border-color": $warning-500,
  "text-color": $warning-700,
  "bg-color": $warning-100,
);
$tooltip-danger: (
  "border-color": $danger-500,
  "text-color": $danger-700,
  "bg-color": $danger-100,
);

$tooltips: (
  "info": $tooltip-info,
  "success": $tooltip-success,
  "warning": $tooltip-warning,
  "danger": $tooltip-danger,
);

@mixin tooltip($bg-color, $text-color, $border-color) {
  .arrow {
    left: 50%;
    margin-left: -0.4rem;
    &::before {
      // left: $tooltip-border-radius;
      border-top-color: $border-color;
      left: 0px;
    }
  }
  .tooltip-inner {
    color: $text-color;
    background: $bg-color;
    border: 1px solid $border-color;
    max-width: fit-content;
  }
}

@each $name, $tooltip in $tooltips {
  .tooltip-#{$name} {
    @include tooltip(
        map_get($tooltip, "bg-color"),
        map_get($tooltip, "text-color"),
        map_get($tooltip, "border-color")
    )
  }
}
