@import "src/assets/styles/colors";
// Overwrite bootstrap

// Base
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
$font-family-sans-serif: Meiryo, "Noto Sans JP", "M PLUS 1p", sans-serif, Lato, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-family-monospace: 'Roboto Mono', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.8125rem; // 13px
$font-size-sm: 0.625rem; // 10px (smaller size)
$font-size-lg: 1rem; // 16px (larger size)

$body-color: $black-900;

// text
$h1-font-size: 34px;
$h2-font-size: 30px;
$h3-font-size: 20px;
$h4-font-size: 17px;
$h5-font-size: 16px;
$h6-font-size: $font-size-base;
$headings-font-weight: bold;

$t1-font-size: 16px;
$t2-font-size: 15px;
$t3-font-size: 14px;
$t4-font-size: 13px;
$t5-font-size: 12px;

$p1-font-size: 16px;
$p2-font-size: 13px;
$p3-font-size: 12px;
$p1-line-height: 30px;
$p2-line-height: 23px;
$p3-line-height: 20px;

// Button
$btn-font-weight: 700;
//$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y: 0.5rem;
$btn-padding-x: 0.75rem;
$btn-font-size: $t3-font-size;

$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 0.5rem;
$btn-font-size-sm: $t4-font-size;

$btn-padding-y-lg: .75rem;
$btn-padding-x-lg: 1em;
$btn-font-size-lg: $t2-font-size;

// Input
$input-placeholder-color: $black-300;
$input-border-color: $black-300;
$input-color: $black-900;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary-500;

$input-font-size: $t3-font-size;
$input-height: auto;
$input-font-size-sm: $t5-font-size;

// table
$table-border-color: $black-200;
$table-cell-padding: .5rem;

// Tooltip
$tooltip-border-radius: 0.3rem;
$tooltip-opacity: 1;
$tooltip-font-size: $t5-font-size;
$tooltip-max-width: 30rem;
$zindex-tooltip: 999999999;

// Nav tab
$nav-tabs-link-active-bg: #FFFFFF;
$nav-tabs-border-width: unset;
$nav-tabs-link-active-color: $black-900;

// extend custom color
// others
$cursors: auto, default, none, context-menu, help, pointer, progress, wait, cell, crosshair, text, vertical-text, alias, copy, move, no-drop, not-allowed, all-scroll, col-resize, row-resize, n-resize, e-resize, s-resize, w-resize, ns-resize, ew-resize, ne-resize, nw-resize, se-resize, sw-resize, nesw-resize, nwse-resize;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$grid-gutter-width: 1rem; // padding Grid (row - col class)
$card-spacer-x: 0.75rem;
$card-spacer-y: 0.5rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$theme-colors: map-merge($theme-colors, $custom-colors);
