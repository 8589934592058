@use "sass:map";

.custom-radio {
  .custom-control-label::before {
    top: 3px;
    left: -15.5px;
    width: 17px;
    height: 17px;
    background-color: #fff;
  }
  .custom-control-label:hover.custom-control-label::before {
    border: 2px solid $primary-500;
  }

  .form-control-label:hover {
    color: $primary-500;
  }

  .custom-control-radio-input:checked ~ .form-control-label::before {
    background-color: #fff;
    border: 2px solid #007bff;
  }

  .form-control-label::after {
    top: 6px;
    left: -12px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
  }


  .custom-control-radio-input:checked ~ .form-control-label::after {
    background-image: none;
    background-color: #007bff;
    border-radius: 50%;
    border: 2px solid white;
  }

  .custom-control-radio-input:disabled ~ .form-control-label::before {
    background-color: #e9ecef;
    border: 2px solid $black-300;
  }


  .custom-control-radio-input:disabled:checked ~ .form-control-label::after {
    background-color: #9ca0b1;
    background-image: none;
    border-radius: 50%;
    border: 2px solid #e9ecef;
  }

}

.form-control-label {
  margin-bottom: .5rem;
  padding-left: 8px;
}

.custom-control-radio-input ~ .form-control-label {
  font-size: $t2-font-size;
  font-weight: 400;
  color: $black-900;
}

.custom-control-radio-input:disabled ~ .form-control-label {
  color: $black-300;
  font-size: $t2-font-size;
  font-weight: 400;
}

.custom-control-radio-input:disabled:hover ~ .custom-control-label::before {
  border: 2px solid $black-300;
}
.custom-control-radio-input:disabled:checked:hover ~ .form-control-label::before {
  border: 2px solid $black-300;
}
