@import "~bootstrap/scss/mixins";

.t1 { @include font-size($t1-font-size);}
.t2 { @include font-size($t2-font-size);}
.t3 { @include font-size($t3-font-size);}
.t4 { @include font-size($t4-font-size);}
.t5 { @include font-size($t5-font-size);}

.p1 {
  @include font-size($p1-font-size);
  line-height: $p1-line-height;
}
.p2 {
  @include font-size($p2-font-size);
  line-height: $p2-line-height;
}
.p3 {
  @include font-size($p3-font-size);
  line-height: $p3-line-height;
}
