@use "sass:map";

.custom-control-label {
  color: $black-900;
}

.custom-checkbox .custom-control-label::before {
  border: 2px solid $black-300;
  border-radius: 25%;
}

.custom-checkbox .custom-control-label::after {
  border-radius: 25%;
}

.custom-control-input ~ .custom-control-label {
  font-size: $t2-font-size;
  font-weight: 400;
  color: $black-900;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: $black-300;
  font-size: $t2-font-size;
  font-weight: 400;
}

//hover
.custom-control-input:disabled ~ .custom-control-label:hover {
  color: $black-300;
}

.custom-checkbox {
  .custom-control-label:hover {
    color: $primary-500;
  }

  //border hover
  .custom-control-label:hover.custom-control-label::before {
    border: 2px solid $primary-500;
  }
  //
  .custom-control-label.custom-control-label::before {
    border: 2px solid $black-300;
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: $white;
    border: 2px solid $black-300;
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    background-color: $black-300;
  }

  // active checked

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $primary-500;
    }

    &::after {
      background-color: $primary-500;
    }
  }
}
